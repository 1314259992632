import ReactEChartsCore from 'echarts-for-react/lib/core';
import { PieChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor } from 'helpers/utils';
import { useAppContext } from 'providers/AppProvider';
import React, { useEffect } from 'react';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer,
  LegendComponent
]);

const getOption = (data) => ({
  tooltip: {
    trigger: 'axis',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('gray-1100') },
    borderWidth: 1,
    transitionDuration: 0,
    axisPointer: {
      type: 'none'
    }
  },
  xAxis: {
    type: 'value',
    boundaryGap: '0%',
    axisLine: {
      show: true,
      lineStyle: {
        color: getColor('gray-300')
      }
    },
    axisTick: { show: true },
    axisLabel: {
      color: getColor('gray-500')
    },
    splitLine: {
      show: false
    },
  },
  yAxis: {
    type: 'category',
    data: data && data.map(item => item.name),
    boundaryGap: '0%',
    axisLabel: {
      formatter: value => value.substring(0, 5),
      show: true,
      color: getColor('gray-500'),
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: getColor('gray-200')
      }
    },
    axisTick: { show: false },
    axisLine: {
      lineStyle: {
        color: getColor('gray-300')
      }
    }
  },
  series: [
    {
      type: 'bar',
      name: 'Total',
      data: data && data.map(item => item.value),
      lineStyle: { color: getColor('primary') },
      itemStyle: {
        color: getColor('primary'),
        borderRadius: [0, 3, 3, 0]
      },
      showSymbol: false,
      symbol: 'circle',
      smooth: false,
      emphasis: {
        scale: true
      }
    }
  ],
  barWidth: '20px',
  grid: { right: '3%', left: '10%', bottom: '10%', top: '5%' }
});

const TicketsByAgentChart = ({ data }) => {
  const {
    config: { isDark }
  } = useAppContext();

  useEffect(() => {
  }, [isDark])
  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getOption(data)}
      style={{ height: '18.75rem' }}
    />
  );
}

export default TicketsByAgentChart;