import useHttp from '../helpers/HttpMethods';
import {
  CountResponse,
  TicketByStatus,
  TicketByCatalog,
  TicketByCustomer,
  TicketByAgent,
  TicketByPriority,
  TicketByServiceGroup,
} from '../types/DashboardTypes';

class DashboardService {
  /**
   * Busca tickets por catálogo.
   * @param {string} filter - Filtros da consulta.
   * @returns {Promise<TicketByCatalog[]>}
   */
  static async getTicketsByCatalog(filter: string): Promise<TicketByCatalog[]> {
    return await useHttp().get(`/dashboards/tickets/by-catalog?${filter}`);
  }

  /**
   * Busca tickets por cliente.
   * @param {string} filter - Filtros da consulta.
   * @returns {Promise<TicketByCustomer[]>}
   */
  static async getTicketsByCustomer(filter: string): Promise<TicketByCustomer[]> {
    return await useHttp().get(`/dashboards/tickets/by-customer?${filter}`);
  }

  /**
   * Busca tickets por agente.
   * @param {string} filter - Filtros da consulta.
   * @returns {Promise<TicketByAgent[]>}
   */
  static async getTicketsByAgent(filter: string): Promise<TicketByAgent[]> {
    return await useHttp().get(`/dashboards/tickets/by-agent?${filter}`);
  }

  /**
   * Busca tickets por prioridade.
   * @param {string} filter - Filtros da consulta.
   * @returns {Promise<TicketByPriority[]>}
   */
  static async getTicketsByPriority(filter: string): Promise<TicketByPriority[]> {
    return await useHttp().get(`/dashboards/tickets/by-priority?${filter}`);
  }

  /**
   * Busca tickets por grupo de serviço.
   * @param {string} filter - Filtros da consulta.
   * @returns {Promise<TicketByServiceGroup[]>}
   */
  static async getTicketsByServiceGroup(filter: string): Promise<TicketByServiceGroup[]> {
    return await useHttp().get(`/dashboards/tickets/by-service-group?${filter}`);
  }

  /**
   * Busca tickets por status.
   * @param {string} filter - Filtros da consulta.
   * @returns {Promise<TicketByStatus[]>}
   */
  static async getTicketsByStatus(filter: string): Promise<TicketByStatus[]> {
    return await useHttp().get(`/dashboards/tickets/by-status?${filter}`);
  }

  /**
   * Busca tickets não atribuídos.
   * @param {string} filter - Filtros da consulta.
   * @returns {Promise<CountResponse>}
   */
  static async getTicketsUnassigned(filter: string): Promise<CountResponse> {
    return await useHttp().get(`/dashboards/tickets/unassigned?${filter}`);
  }

  /**
   * Busca tickets do usuário logado.
   * @param {string} filter - Filtros da consulta.
   * @returns {Promise<CountResponse>}
   */
  static async getMyTickets(filter: string): Promise<CountResponse> {
    return await useHttp().get(`/dashboards/tickets/my-tickets?${filter}`);
  }

  /**
   * Busca tickets com resposta expirada.
   * @param {string} filter - Filtros da consulta.
   * @returns {Promise<CountResponse>}
   */
  static async getTicketsResponseExpireds(filter: string): Promise<CountResponse> {
    return await useHttp().get(`/dashboards/tickets/response-expireds?${filter}`);
  }

  /**
   * Busca tickets com solução expirada.
   * @param {string} filter - Filtros da consulta.
   * @returns {Promise<CountResponse>}
   */
  static async getTicketsSolutionExpireds(filter: string): Promise<CountResponse> {
    return await useHttp().get(`/dashboards/tickets/solution-expireds?${filter}`);
  }

  /**
   * Busca tickets com SLA pausado.
   * @param {string} filter - Filtros da consulta.
   * @returns {Promise<CountResponse>}
   */
  static async getTicketsWithSlaPaused(filter: string): Promise<CountResponse> {
    return await useHttp().get(`/dashboards/tickets/sla-paused?${filter}`);
  }

  /**
   * Busca pré-tickets.
   * @returns {Promise<CountResponse>}
   */
  static async getPreTickets(): Promise<CountResponse> {
    return await useHttp().get(`/dashboards/tickets/pre-tickets`);
  }

  /**
   * Busca tickets respondidos.
   * @param {string} filter - Filtros da consulta.
   * @returns {Promise<CountResponse>}
   */
  static async getAnswered(filter: string): Promise<CountResponse> {
    return await useHttp().get(`/dashboards/tickets/answered?${filter}`);
  }
}

export default DashboardService;