import { faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FalconComponentCard from "components/common/FalconComponentCard";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import PauseReasonEditModal from "components/modals/PauseReasonEditModal";
import PauseReasonRegistrationModal from "components/modals/PauseReasonRegistrationModal";
import Pagination from "components/pagination";
import FailedRequest from "components/requests-response/FailedRequest";
import SuccessRequest from "components/requests-response/SuccessRequest";
import useApi from 'helpers/api';
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

const PauseReasonRegistration = () => {
  const api = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);

  const [selectedPauseReason, setSelectedPauseReason] = useState({});
  const [error, setError] = useState({});
  const [success, setSuccess] = useState('');
  const [pauseReasons, setPauseReasons] = useState({ content: [] });
  const [filters, setFilters] = useState({
    page: 0,
    size: 10,
    includeInactives: false
  });

  const getData = async () => {
    setIsLoading(true);
    const queryParams = new URLSearchParams(filters).toString();
    const response = await api.pauseReasons.list(queryParams);
    if (response.content) setPauseReasons(response);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, [filters]);

  const columns = [
    {
      accessorKey: 'name',
      header: 'Nome',
      meta: {
        headerProps: { className: 'text-900' }
      },
    },
    {
      accessorKey: 'inactive',
      header: 'Status',
      meta: {
        headerProps: { className: 'text-900' },
      },
      cell: rowData => {
        const { inactive } = rowData.row.original;
        return (
          inactive
            ? <span className="text-danger">Inativo</span>
            : <span className="text-success">Ativo</span>
        );
      }
    },
    {
      accessorKey: 'actions',
      header: 'Ações',
      meta: {
        headerProps: { className: 'text-900' },
      },
      cell: rowData => {
        const item = rowData.row.original;
        return (
          <button className="btn btn-link btn-sm" onClick={() => handleEdit(item)}>
            <FontAwesomeIcon icon={faPen} size="sm" />
          </button>
        );
      }
    }
  ];

  const table = useAdvanceTable({
    data: pauseReasons.content,
    columns
  })

  const handleCloseModalRegister = () => {
    setShowModal(false);
  };

  const handleCloseModalEdit = () => {
    setShowModalEdit(false);
  }

  const handleEdit = item => {
    setSelectedPauseReason(item);
    setShowModalEdit(true);
  };

  const editContact = async bodyChange => {
    let response = await api.contacts.edit(selectedPauseReason.id, bodyChange);
    if (response) {
      getData();
      handleCloseModalEdit();
      setSuccess("Motivo editado com sucesso");
      setShowAlert(true);
    }
  }

  return (
    <>
      <SuccessRequest message={success} showAlert={showAlert} setShowAlert={() => setShowAlert(false)} />
      <FailedRequest message={error.userMessage} showAlert={showAlertError} setShowAlert={() => setShowAlertError(false)} />
      <FalconComponentCard>
        <FalconComponentCard.Header title="Motivos de pausa">
          <div className="w-100 d-flex justify-content-between">
            <Button type="button" variant="primary" size="sm" onClick={() => setShowModal(true)}>
              <FontAwesomeIcon icon={faPlus} size="sm" className="me-2" />
              Novo
            </Button>
          </div>
        </FalconComponentCard.Header>
        <FalconComponentCard.Body>
          <PauseReasonRegistrationModal
            showModal={showModal}
            handleClose={() => handleCloseModalRegister()}
            isSuccess={response => response && getData()} />

          <PauseReasonEditModal
            showModal={showModalEdit}
            handleClose={() => handleCloseModalEdit()}
            data={selectedPauseReason}
            isSuccess={response => response && getData()} />

          <Row className="mb-3 justify-content-end">
            <Col className="col-auto px-3">
              <Form.Select
                size="sm"
                className="mb-3 rounded-pill"
                onChange={e => setFilters({ ...filters, includeInactives: e.target.value })}>
                <option value="true">Filtro por status</option>
                <option value="true">Todos</option>
                <option value="false">Somente ativos</option>
              </Form.Select>
            </Col>
          </Row>

          {isLoading ? (
            <Skeleton count={10} height={35} baseColor="#5E6E82" />
          ) : (
            <>
              <AdvanceTableProvider {...table}>
                <AdvanceTable
                  table
                  headerClassName="bg-100 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    bordered: true,
                    striped: true,
                    className: 'fs-10 mb-0 overflow-hidden'
                  }} />
              </AdvanceTableProvider>
              <Pagination
                data={pauseReasons}
                filters={filters}
                setFilters={setFilters} />
            </>
          )}
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>
  );
};

export default PauseReasonRegistration;