import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import React, { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';

const TrItem = ({ name, value, className }) => {
  return (
    <tr style={{ borderColor: 'transparent' }}>
      <td className="fw-semibold pb-0">{name} </td>
      <td className={` pb-0 ${className}`}>{value || '---'}</td>
    </tr>
  );
};

const AssetCustomFields = ({ data }) => {
  const activeCustomProperties = data.assetProperties || [];

  const [showBody, setShowBody] = useState(true);

  const getValueByType = (assetPropertyValue) => {
    if (!assetPropertyValue) return '---';

    if (assetPropertyValue.stringValue !== undefined) return assetPropertyValue.stringValue;
    if (assetPropertyValue.intValue !== undefined) return assetPropertyValue.intValue;
    if (assetPropertyValue.decimalValue !== undefined) return assetPropertyValue.decimalValue;
    if (assetPropertyValue.textValue) return assetPropertyValue.textValue;
    if (assetPropertyValue.boolValue !== undefined) return assetPropertyValue.boolValue ? 'Sim' : 'Não';
    if (assetPropertyValue.datetimeValue) return new Date(assetPropertyValue.datetimeValue).toLocaleString();

    return '---';
  };


  return (
    <Card className="w-100">
      <Flex
        className="w-100 p-3 bg-100 rounded-top-4"
        onClick={() => setShowBody(!showBody)}
        role="button"
        justifyContent="between"
      >
        <h6 className="m-0">CAMPOS PERSONALIZADOS</h6>
        <FontAwesomeIcon icon={showBody ? faChevronUp : faChevronDown} size="sm" />
      </Flex>
      {showBody && (
        <Card.Body className='w-100'>
          <Row>
            {activeCustomProperties.length > 0 ? (
              activeCustomProperties.map((property) => (
                <Col md={4}>
                  <td
                    key={property.id}
                    style={{ width: '33%' }}
                    className={`pb-0`}>
                    <strong>{property.name}</strong>: {getValueByType(property.assetPropertyValue) || '---'}
                  </td>
                </Col>
              ))
            ) : (
              <TrItem name="Nenhum campo personalizado encontrado" value="---" />
            )}
          </Row>
        </Card.Body>
      )}
    </Card>
  );
};

export default AssetCustomFields;
