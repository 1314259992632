import Flex from "components/common/Flex";
import MultiSelect from "components/common/MultiSelect";
import useApi from "helpers/api";
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

const AlertFilteringForm = ({ filters, setFilters }) => {
  const api = useApi();

  const [formattedCustomers, setFormattedCustomers] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [customers, setCustomers] = useState([]);

  const [formattedDevices, setFormattedDevices] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [devices, setDevices] = useState([]);

  const [formattedDattoPriorities, setFormattedDattoPriorities] = useState([]);
  const [selectedDattoPriorities, setSelectedDattoPriorities] = useState([]);
  const [dattoPriorities, setDattoPriorities] = useState([]);
  const [filtersQuery] = useState({ size: 1000 });

  useEffect(() => {
    getCustomers();
    getDevices();
    getDattoPriorities();
  }, []);

  useEffect(() => {
    setFormattedCustomers(customers.map(item => ({
      value: item.id,
      label: item.name
    })))
  }, [customers]);

  useEffect(() => {
    setFormattedDevices(devices.map(item => ({
      value: item.id,
      label: item.hostname
    })))
  }, [devices]);

  useEffect(() => {
    setFormattedDattoPriorities(dattoPriorities.map(item => ({
      value: item.value,
      label: item.description
    })))
  }, [dattoPriorities]);

  useEffect(() => {
    setFilters({ ...filters, customers: selectedCustomers.map(item => item.value) });
  }, [selectedCustomers]);

  useEffect(() => {
    setFilters({ ...filters, devices: selectedDevices.map(item => item.value) });
  }, [selectedDevices]);

  useEffect(() => {
    setFilters({ ...filters, priorities: selectedDattoPriorities.map(item => item.value) });
  }, [selectedDattoPriorities]);

  const getCustomers = async () => {
    const params = new URLSearchParams(filtersQuery).toString();
    let response = await api.customers.list(params);
    response.content && setCustomers(response.content);
  };

  const getDevices = async () => {
    const params = new URLSearchParams(filtersQuery).toString();
    let response = await api.dattoDevices.list(params);
    response.content && setDevices(response.content);
  };

  const getDattoPriorities = async () => {
    const response = await api.dattoPriorities.list();
    response && setDattoPriorities(response);
  }

  const multiSelectStyles = {
    control: (base) => ({
      ...base,
      height: '29px',
      fontSize: '0.875rem',
    }),
    valueContainer: (base) => ({
      ...base,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      padding: '0 0.5rem',
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: '100%',
    }),
  };

  return (
    <Row className="w-100 g-0">
      <Flex
        className="w-100 mb-3"
        justifyContent="between"
        style={{
          flexWrap: 'wrap',
          gap: '1rem',
          alignItems: 'center'
        }}
      >
        <Flex className="w-75 g-3">
          <Col className="me-2" style={{ flex: '1 1 100px', maxWidth: '200px' }}>
            <Form.Group>
              <MultiSelect
                placeholder="Clientes"
                options={formattedCustomers}
                value={selectedCustomers}
                onChange={values => setSelectedCustomers(values)}
                styles={multiSelectStyles}
              />
            </Form.Group>
          </Col>
          <Col className="me-2" style={{ flex: '1 1 100px', maxWidth: '200px' }}>
            <Form.Group>
              <MultiSelect
                placeholder="Dispositivos"
                options={formattedDevices}
                value={selectedDevices}
                onChange={values => setSelectedDevices(values)}
                styles={multiSelectStyles} />
            </Form.Group>
          </Col>

          <Col className="me-2" style={{ flex: '1 1 100px', maxWidth: '200px' }}>
            <Form.Group>
              <MultiSelect
                placeholder="Prioridades"
                options={formattedDattoPriorities}
                value={selectedDattoPriorities}
                onChange={values => setSelectedDattoPriorities(values)}
                styles={multiSelectStyles} />
            </Form.Group>
          </Col>

          <Col className="me-2" style={{ flex: '1 1 100px', maxWidth: '250px' }}>
            <Form.Control
              type='text'
              placeholder="Descrição"
              size='sm'
              value={filters.description}
              onChange={e => setFilters({ ...filters, description: e.target.value })}
            />
          </Col>
        </Flex>

        <Col style={{ flex: '1 1 100px', maxWidth: '160px' }}>
          <Form.Select
            size="sm"
            className="rounded-pill"
            onChange={e => {
              const value = e.target.value;
              setFilters(prevFilters => {
                if (value === 'null') {
                  const { resolved, ...filters } = prevFilters;
                  return filters;
                } else {
                  return {
                    ...prevFilters,
                    resolved: value
                  }
                }
              })
            }}
          >
            <option value="null">Filtro por status</option>
            <option value="null">Todos</option>
            <option value="true">Somente resolvidos</option>
            <option value="false">Somente não resolvidos</option>
          </Form.Select>
        </Col>
      </Flex>
    </Row>
  );
};

export default AlertFilteringForm;
