import { faChevronDown, faChevronUp, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import TicketContactEditModal from 'components/modals/TicketContactEditModal';
import useApi from 'helpers/api';
import { useAppContext } from 'providers/AppProvider';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Image, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import msptalksDark from '../../../../assets/img/integrations/logo-dark-msptalks.png';
import msptalks from '../../../../assets/img/integrations/logo-msptalks.png';

const ContactInfo = ({ data }) => {
  const api = useApi();
  const [tickets, setTickets] = useState({ content: [] });
  const [customerId, setCustomerId] = useState('');
  const [filters, setFilters] = useState({ size: 5, customer: '' });
  const [contact, setContact] = useState({});

  const [viewLastTickets, setViewLastTickets] = useState(false);
  const [showBody, setShowBody] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const {
    config: { isDark }
  } = useAppContext();

  useEffect(() => {
    setFilters(prevFilters => ({ ...prevFilters, customer: data.customer?.id }));
    setCustomerId(data.customer?.id);
    setContact(data.contactCustomer);
  }, [data]);

  useEffect(() => {
    getTickets();
  }, [customerId]);

  const getTickets = async () => {
    const queryParams = new URLSearchParams(filters).toString();
    let response = await api.tickets.list(queryParams);
    setTickets(response);
  };

  const openChat = (e, url) => {
    e.preventDefault();

    const width = 800;
    const height = 600;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;

    window.open(
      url,
      'popupWindow',
      `width=${width},height=${height},top=${top},left=${left},scrollbars=yes,location=no,noopener,noreferrer`
    );
  };


  return (
    <>
      <TicketContactEditModal
        show={showModal}
        close={() => setShowModal(false)}
        ticket={data}
        setContact={setContact} />
      <Card>
        <Flex
          alignItems="center"
          justifyContent="between"
          className="w-100 bg-100 card-header py-2"
          role='button'
          onClick={() => setShowBody(!showBody)}>
          <h6 className="m-0">{data.customer?.fantasyName || data.customer?.name}</h6>
          <FontAwesomeIcon icon={showBody ? faChevronUp : faChevronDown} size="sm" />
        </Flex>

        {showBody &&
          <Card.Body>
            <Row className="g-0 pb-x1 align-items-sm-center align-items-xl-start">
              <Col xs={12} sm="auto" xl={12}>
                <h6 className="m-0">Nome</h6>
                <Flex alignItems="center">
                  <p className="fs-10 m-0 me-2">{contact?.name}</p>
                  <Button
                    variant='link'
                    size='sm'
                    className='p-0 me-1'
                    onClick={() => setShowModal(true)}>
                    <OverlayTrigger overlay={<Tooltip>Alterar contato</Tooltip>} placement='top'>
                      <span><FontAwesomeIcon icon={faPencilAlt} size="sm" /></span>
                    </OverlayTrigger>
                  </Button>

                  {data.origin?.ticketOrigin === 'MSP_TALKS' && data.urlMsptalks &&
                    <Button
                      size='sm'
                      variant='link'
                      className='text-600 p-0'
                      as={Link}
                      to={data?.urlMsptalks}
                      onClick={e => openChat(e, data?.urlMsptalks)}>
                      <OverlayTrigger overlay={<Tooltip>Chat MSP Talks</Tooltip>} placement='top'>
                        <Image style={{ width: 18 }} src={isDark ? msptalksDark : msptalks} />
                      </OverlayTrigger>
                    </Button>
                  }
                </Flex>
              </Col>
            </Row>
            <Row className="g-0 justify-content-lg-between">
              <Row>
                <Col xs="auto" md={6} lg="auto" className="ps-md-5 ps-xl-0">
                  {contact && contact.email &&
                    <>
                      <h6 className="m-0">E-mail</h6>
                      <a href={`mailto:${contact ? contact.email : ''}`} className="fs-10 mb-1">
                        {contact && contact.email ? contact.email : '---'}
                      </a>
                    </>
                  }

                  {contact && contact.landline &&
                    <>
                      <h6 className="mt-2 mb-0">Telefone</h6>
                      <a href={`tel:'${contact && contact.landline ? contact.landline : '---'}`} className="fs-10 mb-1">
                        {contact && contact.landline ? contact.landline : '---'}
                      </a>
                    </>
                  }

                  {contact && contact.phone &&
                    <>
                      <h6 className="mt-2 mb-0">Celular</h6>
                      <a href={`tel:'${contact && contact ? contact.phone : ''}`} className="fs-10">
                        {contact && contact.phone ? contact.phone : '---'}
                      </a>
                    </>
                  }
                  <div className='w-100 mt-3'>
                    <h6 role='button' className="mt-2 mb-0 fs-10"
                      onClick={() => setViewLastTickets(!viewLastTickets)}>
                      {viewLastTickets ? 'Esconder' : 'Ver'} Últimos Tickets {'>'}
                    </h6>
                  </div>
                  {viewLastTickets && <div className='mt-3'>
                    {tickets.content && tickets.content.map((item, i) => (
                      <Link to={`/tickets/${item.id}`} key={i} className='d-block w-100 fs-10 mb-2' style={{ overflowX: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                        #{item.code} {item.subject}
                      </Link>
                    ))}
                  </div>}
                </Col>
              </Row>
            </Row>
          </Card.Body>
        }
      </Card>
    </>
  );
};

export default ContactInfo;