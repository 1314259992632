import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconComponentCard from 'components/common/FalconComponentCard';
import Flex from 'components/common/Flex';
import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const tdStyling = "pb-0";

const AssetContact = ({ data }) => {
  const [showBody, setShowBody] = useState(true);
  return (
    <FalconComponentCard>
      <Flex
        className="p-3 bg-100 w-100 rounded-top-4"
        onClick={() => setShowBody(!showBody)}
        role="button" justifyContent="between">
        <h6 className="m-0">CONTATO</h6>
        <FontAwesomeIcon icon={showBody ? faChevronUp : faChevronDown} size="sm" />
      </Flex>
      {showBody &&
        <Table >
          <tbody>
            <tr style={{ borderColor: 'transparent' }} >
              <td className={`fw-semibold w-50 pb-0`}>Cliente</td>
              <td className={`pb-0`}>
                {data.customer ? (
                  <Link to={`/registration/customers/${data.customer.id}`}>
                    {data.customer.name}
                  </Link>
                ) : (
                  '---'
                )}
              </td>
            </tr>
            <tr style={{ borderColor: 'transparent', }}>
              <td className={`fw-semibold w-50 pb-0`}>Contato</td>
              <td className="pb-0">{data.contact?.name || '---'}</td>
            </tr>
            <tr style={{ borderColor: 'transparent' }}>
              <td className={`fw-semibold w-50 pb-0`}>Status</td>
              <td className={`pb-0 ${data.customer?.inactive ? 'text-danger' : 'text-success'}`}>
                {data.customer?.inactive ? 'Inativo' : 'Ativo'}
              </td>
            </tr>
          </tbody>
        </Table>
      }
    </FalconComponentCard>
  );
};

export default AssetContact;