import FailedRequest from 'components/requests-response/FailedRequest';
import SuccessRequest from 'components/requests-response/SuccessRequest';
import TicketDetails from 'components/tickets/TicketDetails';
import useApi from 'helpers/api';
import { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import AssetInfo from './AssetInfo';
import ContactInfo from './ContactInfo';
import DattoDeviceDetails from './DattoDeviceDetails';
import PropertiesFilteringForm from './PropertiesFilteringForm';
import SlaInfo from './SlaInfo';
import TicketsPreviewHeader from './TicketsPreviewHeader';

const TicketsPreview = () => {
  const { id } = useParams();
  const api = useApi();

  const ticketDetails = useRef();

  const [ticket, setTicket] = useState({});
  const [error, setError] = useState({});

  const [success, setSuccess] = useState('');
  const [sizeMainSide, setSizeMainSide] = useState(6);

  const [isSuccess, setIsSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showLeftSide, setShowLeftSide] = useState(true);
  const [showRightSide, setShowRightSide] = useState(true);

  useEffect(() => {
    getDetails();
  }, [id]);

  useEffect(() => {
    if (showLeftSide && showRightSide) {
      setSizeMainSide(6);
    } else if (showLeftSide || showRightSide) {
      setSizeMainSide(9);
    } else if (!showLeftSide && !showRightSide) {
      setSizeMainSide(12);
    }
  }, [showLeftSide, showRightSide]);

  const getDetails = async () => {
    let response = await api.tickets.findById(id);
    setTicket(response);
  };

  const editTicket = async body => {
    let response = await api.tickets.edit(ticket.id, body);
    if (response.status) {
      getDetails();
      ticketDetails.current.getNewLogs();
      setIsSuccess(true);
      setSuccess("Ticket editado com sucesso");
    } else {
      setError(response);
      setShowError(true);
    }
  }

  return (
    <>
      <SuccessRequest
        message={success}
        showAlert={isSuccess}
        setShowAlert={() => setIsSuccess(false)} />
      <FailedRequest
        message={error.userMessage}
        showAlert={showError}
        setShowAlert={(() => setShowError(false))} />
      <Row>
        <Col xl={12}>
          <TicketsPreviewHeader
            data={ticket}
            refreshData={() => getDetails()}
            refreshLogs={() => ticketDetails.current.getNewLogs()}
            refreshFiles={() => ticketDetails.current.getFiles()}
            refreshNotes={() => ticketDetails.current.getNotes()}
            success={success => {
              if (success != null) {
                setIsSuccess(true);
                setSuccess(success);
              }
            }}
            error={error => {
              if (error != null) {
                setError(error);
                setShowError(true);
              }
            }} />
        </Col>
        {showLeftSide &&
          <Col xxl={3} xl={3} className={`${showLeftSide && 'ps-3 pe-2'}`}>
            <Row className="gy-3 gx-0">
              <Col xs={12}>
                <ContactInfo
                  data={ticket} />
              </Col>
              <Col md={6} xl={12}>
                <PropertiesFilteringForm data={ticket} handleSubmit={editTicket} />
              </Col>
            </Row>
          </Col>
        }

        <Col md={sizeMainSide} className={`px-0 ${!showLeftSide && 'ps-3'} ${!showRightSide && 'pe-3'}`}>
          <div className='position-sticky'>
            <TicketDetails
              data={ticket}
              refreshData={() => getDetails()}
              showLeftSide={showLeftSide}
              showRightSide={showRightSide}
              setShowLeftSide={setShowLeftSide}
              setShowRightSide={setShowRightSide}
              ref={ticketDetails} />
          </div>
        </Col>

        {showRightSide &&
          <Col xxl={3} xl={3} className={`${showLeftSide && 'ps-2 pe-3'}`}>
            <Row className="g-3">
              <Col md={6} xl={12}>
                <SlaInfo data={ticket} refreshData={() => getDetails()} />
              </Col>
              {ticket?.asset?.dattoDevice &&
                <Col md={6} xl={12}>
                  <DattoDeviceDetails data={ticket?.asset?.dattoDevice} />
                </Col>
              }
              <Col md={6} xl={12}>
                <AssetInfo data={ticket} refreshData={() => getDetails()} />
              </Col>
            </Row>
          </Col>
        }
      </Row>
    </>
  );
};

export default TicketsPreview;