import { useParams } from "react-router-dom";
import useApi from 'helpers/api';
import { useEffect, useState } from "react";
import Flex from "components/common/Flex";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDesktop, faMobileAlt, faTabletAlt } from "@fortawesome/free-solid-svg-icons";
import SubtleBadge from "components/common/SubtleBadge";

const AssetMainCard = () => {
  const { id } = useParams();
  const api = useApi();

  const [asset, setAsset] = useState({});

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const response = await api.assets.find(id);
    response.id && setAsset(response);
  };

  return (
    <Flex alignItems="center">
      <Container className='me-5' style={{ position: 'relative', width: '100px', height: '100px' }}>
        <FontAwesomeIcon
          icon={faDesktop}
          style={{
            position: 'absolute',
            fontSize: '4rem',
            color: '#6c757d',
            top: '0',
            left: '20',
            zIndex: 1,
            opacity: 0.85,
            transition: 'transform 0.3s ease, opacity 0.3s ease',
            borderRadius: '7%',
          }}
          className="border border-3 border-100"
        />
        <FontAwesomeIcon
          icon={faTabletAlt}
          style={{
            position: 'absolute',
            fontSize: '3rem',
            color: '#6c757d',
            top: '20px',
            left: '70px',
            zIndex: 2,
            opacity: 0.85,
            transition: 'transform 0.3s ease, opacity 0.3s ease',
            borderRadius: '7%',
          }}
          className="border border-3 border-100" />
        <FontAwesomeIcon
          icon={faMobileAlt}
          style={{
            position: 'absolute',
            fontSize: '2.5rem',
            color: '#6c757d',
            top: '35px',
            left: '95px',
            zIndex: 3,
            opacity: 0.85,
            transition: 'transform 0.3s ease, opacity 0.3s ease',
            borderRadius: '7%',
          }}
          className="border border-3 border-100" />
      </Container>
      <Row className="mb-1 me-5">
        <Col xs={12}>
          <label htmlFor="deviceName" className="text-muted mb-1" style={{ fontWeight: '600' }}>
            Nome do ativo:
          </label>
          <h5 id="deviceName" className="mb-0">{asset.name || '---'}</h5>
        </Col>
      </Row>
      <Row className="mb-1 me-5">
        <Col xs={12}>
          <label htmlFor="deviceId" className="text-muted mb-1" style={{ fontWeight: '600' }}>
            ID do ativo:
          </label>
          <h5 id="deviceId" className="mb-0">{asset.id || '---'}</h5>
        </Col>
      </Row>
      <SubtleBadge bg="info">{asset.assetType?.name}</SubtleBadge>
    </Flex>
  )
}
export default AssetMainCard;