import Kanban from 'components/app/kanban/Kanban';
import AllTicketsHeader from 'components/app/support-desk/tickets-layout/AllTicketsHeader';
import CardLayout from 'components/app/support-desk/tickets-layout/CardLayout';
import TicketFilteringForm from 'components/app/support-desk/tickets-layout/TicketFilteringForm';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import Loading from 'components/loading/Loading';
import Pagination from 'components/pagination';
import { FilterContext } from 'context/Context';
import useApi from 'helpers/api';
import { useBreakpoints } from 'hooks/useBreakpoints';
import useBulkSelect from 'hooks/useBulkSelect';
import useTicketsTable from 'hooks/useTicketsTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import React, { useContext, useEffect, useState } from 'react';
import { Card, Col, Offcanvas, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

const ListTickets = () => {
  const api = useApi();

  const [isLoading, setIsLoading] = useState(true);
  const [tickets, setTickets] = useState({ content: [] });
  const { filters, setFilters } = useContext(FilterContext);

  const [showOptionsFilter, setShowOptionsFilter] = useState(false);
  const [show, setShow] = useState(false);
  const [typeView, setTypeView] = useState('');
  const [toggleViewFilter, setToggleViewFilter] = useState(false);
  const { selectedItems, isSelectedItem, toggleSelectedItem } =
    useBulkSelect(tickets.content.map(item => item.id));
  const { breakpoints } = useBreakpoints();

  const table = useTicketsTable({
    data: tickets.content,
    selection: true
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    getTickets();
  }, [filters]);

  useEffect(() => {
    const isTypeView = localStorage.getItem('typeView');
    isTypeView && setTypeView(isTypeView);
  }, []);

  useEffect(() => {
    localStorage.setItem('typeView', typeView);
  }, [typeView]);

  const getTickets = async () => {
    const queryParams = new URLSearchParams(filters).toString();
    let response = await api.tickets.list(queryParams);
    if (response.content) {
      setTickets(response);
      setIsLoading(false);
    }
  };

  const handleSetFilters = (filterName, value) => {
    setFilters(prevState => ({ ...prevState, [filterName]: value }));
  };

  const clearFilters = () => {
    setFilters({
      page: 0,
      size: 10,
      code: '',
      priorities: '',
      status: '',
      customer: '',
      serviceCatalog: '',
      category: '',
      subcategory: '',
      subject: '',
      agent: '',
      typeTicket: '',
      initialCreationDate: '',
      endCreationDate: '',
      initialSolutionDate: '',
      endSolutionDate: '',
      statusSlaResponse: '',
      statusSlaSolution: '',
      createdBy: '',
      defaultFilter: '',
      contact: '',
    });
  };

  return (
    <>
      <Row className="gx-3">
        <Col
          xxl={toggleViewFilter || showOptionsFilter ? 9 : 12}
          xl={toggleViewFilter || showOptionsFilter ? 9 : 12}
          sm={toggleViewFilter || showOptionsFilter && 12}>
          <Card>
            <Card.Header className="border-bottom border-200 px-0">
              <AllTicketsHeader
                table
                layout="table-view"
                handleShow={handleShow}
                toggleViewFilter={toggleViewFilter}
                setToggleViewFilter={setToggleViewFilter}
                globalFilter={filters.subject}
                handleSetFilters={handleSetFilters}
                clearFilters={clearFilters}
                typeView={typeView}
                setTypeView={setTypeView}
                showPreMadeFilters={showOptionsFilter}
                setShowPreMadeFilters={setShowOptionsFilter}
              />
            </Card.Header>
            <Card.Body className="p-0">
              {typeView == 0 ?
                isLoading ? (
                  <Skeleton count={10} height={44} baseColor="#5E6E82" />
                ) : (
                  <>
                    <AdvanceTableProvider {...table}>
                      <AdvanceTable
                        table
                        headerClassName="text-800 align-middle"
                        rowClassName="btn-reveal-trigger align-middle"
                        tableProps={{
                          size: 'sm',
                          className: 'fs-10 mb-0 overflow-hidden'
                        }}
                      />
                    </AdvanceTableProvider>
                    <Card.Footer>
                      {typeView != 2 &&
                        <Pagination
                          data={tickets}
                          filters={filters}
                          setFilters={setFilters} />}
                    </Card.Footer>
                  </>
                ) : typeView == 1 ? (
                  <CardLayout
                    data={tickets.content}
                    isSelectedItem={isSelectedItem}
                    toggleSelectedItem={toggleSelectedItem}
                  />
                ) : typeView == 2 && (
                  <Kanban filters={filters} />
                )
              }

            </Card.Body>

          </Card>
        </Col>
        {toggleViewFilter &&
          <Col xxl={3} xl={3} sm={12}>
            {breakpoints.down('xl') ? (
              <Offcanvas
                show={show}
                onHide={handleClose}
                placement="end"
                className="dark__bg-card-dark"
              >
                <Offcanvas.Header closeButton className="bg-light">
                  <h6 className="fs-0 mb-0 fw-semi-bold">Filter</h6>
                </Offcanvas.Header>
                <TicketFilteringForm filters={filters} setFilters={setFilters} clearFilters={clearFilters} />
              </Offcanvas>
            ) : (
              <TicketFilteringForm filters={filters} setFilters={setFilters} clearFilters={clearFilters} />
            )}
          </Col>
        }
      </Row>
    </>
  );
};

export default ListTickets;