import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FalconComponentCard from "components/common/FalconComponentCard";
import Flex from "components/common/Flex";
import IconButton from "components/common/IconButton";
import Editor from "components/editor/Editor";
import FailedRequest from "components/requests-response/FailedRequest";
import SuccessRequest from "components/requests-response/SuccessRequest";
import useApi from "helpers/api";
import { formatDateTime } from "helpers/utils";
import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";

const AssetEdit = () => {
  const { id } = useParams();
  const api = useApi();
  const navigate = useNavigate();

  const inputCustomer = useRef(null);

  const [asset, setAsset] = useState(null);
  const [formData, setFormData] = useState({});

  const [showMenuCustomer, setShowMenuCustomers] = useState(false);
  const [showCustomFields, setShowCustomFields] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");

  const [filterQuery, setFilterQuery] = useState({
    size: 1000,
    name: ''
  });

  const [assetTypes, setAssetTypes] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    getData();
    getAssetTypes();
  }, []);

  useEffect(() => {
    getCustomers();
  }, [filterQuery.name]);

  useEffect(() => {
    getContacts();
  }, [formData.customer]);

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  const getData = async () => {
    const response = await api.assets.find(id);
    if (response.id) {
      setAsset(response);
      initializeFormData(response);
    }
  };

  const getAssetTypes = async () => {
    const queryParams = new URLSearchParams(filterQuery).toString();
    const response = await api.assetTypes.list(queryParams);

    response.content && setAssetTypes(response.content);
  };

  const getCustomers = async () => {
    if (filterQuery.name != '') {
      const queryParams = new URLSearchParams(filterQuery).toString();
      const response = await api.customers.list(queryParams);

      response.content && setCustomers(response.content);
    }
  };

  const getContacts = async () => {
    if (formData.customer?.id) {
      const queryParams = new URLSearchParams(filterQuery).toString();
      const response = await api.customersContacts.list(formData.customer.id, queryParams);

      response.content && setContacts(response.content);
    }
  };

  const formatDateTimeToFormData = (dateTimeString) => {
    return dateTimeString.slice(0, 16);
  };

  const mapPropertyValue = (prop) => {
    if (!prop.assetPropertyValue) return "";

    const {
      stringValue,
      intValue,
      decimalValue,
      textValue,
      boolValue,
      datetimeValue,
    } = prop.assetPropertyValue;
    const type = prop.dataType.dataType;

    const valueMap = {
      TEXT: stringValue || "",
      DROPDOWN: stringValue || null,
      DATETIME: datetimeValue ? formatDateTimeToFormData(datetimeValue) : null,
      INTEGER: intValue ? parseInt(intValue) : null,
      DECIMAL: decimalValue ? parseFloat(decimalValue) : null,
      TEXT_AREA: textValue || "",
      CHECKBOX: boolValue,
    };

    return valueMap[type] ?? null;
  };

  const initializeFormData = (asset) => {
    const properties = asset.assetProperties?.map((prop) => ({
      propertyId: prop.id,
      ...(prop.assetPropertyValue?.id && { propertyValueId: prop.assetPropertyValue.id }),
      value: mapPropertyValue(prop),
    })) || [];

    setFormData({
      name: asset.name,
      serialNumber: asset.serialNumber || "",
      description: asset.description || "",
      assetType: { id: asset.assetType?.id || 0 },
      customer: { id: asset.customer?.id || 0 },
      contact: { id: asset.contact?.id || 0 },
      inactive: asset.inactive || false,
      properties,
    });

    setFilterQuery({ ...filterQuery, name: asset.customer?.name || '' });
  };

  const updatePropertyValue = (dataType, value, checked) => {
    switch (dataType) {
      case "TEXT":
      case "TEXT_AREA":
      case "DROPDOWN":
        return value || null;

      case "INTEGER":
        return value !== "" && !isNaN(value) ? parseInt(value, 10) : null;

      case "DECIMAL":
        return value !== "" && !isNaN(value) ? parseFloat(value) : null;

      case "CHECKBOX":
        return checked;

      case "DATETIME":
        return value || null;

      default:
        return value;
    }
  };

  const handleChangeCustomFields = (e, propertyId, dataType) => {
    const { name, value, type, checked } = e.target;

    setFormData((prev) => ({
      ...prev,
      properties: prev.properties.map((prop) =>
        prop.propertyId === propertyId
          ? { ...prop, value: updatePropertyValue(dataType.dataType, value, checked) }
          : prop
      ),
    }));
  };


  const handleChange = (e, isId) => {
    const { name, value } = e.target;
    if (isId) {
      setFormData((prev) => ({
        ...prev,
        [name]: { id: parseInt(value) },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const isValidValue = (value) => {
    return value !== "" && value !== null && value !== undefined;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const filteredProperties = formData.properties.filter(
      (prop) => isValidValue(prop.value)
    );

    const filteredFormData = {
      ...formData,
      properties: filteredProperties,
    };

    if (formData.contact?.id === 0) {
      delete filteredFormData.contact;
    }

    const response = await api.assets.edit(id, filteredFormData);

    if (response.id) {
      setShowSuccess(true);
      getData();
    } else {
      setError(response.userMessage);
      setShowError(true);
    }
  };

  if (!asset) return <p>Carregando...</p>;

  return (
    <>
      <SuccessRequest
        showAlert={showSuccess}
        setShowAlert={() => setShowSuccess(false)}
        message="Ativo editado com sucesso" />
      <FailedRequest
        showAlert={showError}
        setShowAlert={() => setShowError(false)}
        message={error} />
      <FalconComponentCard>
        <Card.Header>
          <Flex alignItems="center" justifyContent="between" className="w-100">
            <Flex alignItems="center">
              <IconButton
                onClick={() => navigate(-1)}
                variant="falcon-default"
                size="sm"
                icon="arrow-left"
                className="me-2"
              />
              <h3>Editar ativo</h3>
            </Flex>
            <div>
              <h6 className="fs-9 mb-0">Criado em</h6>
              <p className="fs-10 mb-0 text-600">{formatDateTime(asset.creationDate)}</p>
            </div>
          </Flex>
        </Card.Header>

        <FalconComponentCard.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Form.Group as={Col} md="6" className="mb-3">
                <Form.Label>Nome <span className="text-danger"> *</span></Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  required
                  onChange={handleChange} />
              </Form.Group>

              <Form.Group as={Col} md="6" className="mb-3">
                <Form.Label>Número de série</Form.Label>
                <Form.Control
                  type="text"
                  name="serialNumber"
                  value={formData.serialNumber}
                  onChange={handleChange} />
              </Form.Group>

              <Form.Group as={Col} md="4" className="mb-3">
                <Form.Label>Tipo de ativo <span className="text-danger"> *</span></Form.Label>
                <Form.Select
                  name="assetType"
                  value={formData.assetType?.id}
                  onChange={e => handleChange(e, true)}
                  required>
                  <option>Selecione...</option>
                  {assetTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} md="4" className="mb-3">
                <Form.Label>Cliente <span className="text-danger"> *</span></Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Digite para pesquisar"
                  value={filterQuery.name}
                  ref={inputCustomer}
                  autocomplete="off"
                  autocorrect="off"
                  autocapitalize="off"
                  spellcheck="false"
                  onFocus={() => setShowMenuCustomers(true)}
                  onChange={e =>
                    setFilterQuery(prevFilters => ({
                      ...prevFilters,
                      name: e.target.value
                    }))
                  }
                />
                {showMenuCustomer && (
                  <ul
                    className="position-sticky px-0 list-customer bg-100 shadow rounded z-3"
                    style={{ height: 'auto', maxHeight: 350, overflowY: 'auto' }}
                  >
                    {customers.map(item => (
                      <li
                        style={{ listStyleType: 'none', cursor: 'pointer' }}
                        className="px-3 py-2 bg-primary-hover item-list-hover"
                        data-value={item.id}
                        key={item.id + item.type}
                        onClick={() => {
                          setFilterQuery(prevFilters => ({
                            ...prevFilters,
                            name: item.fantasyName || item.name
                          }));
                          setFormData({ ...formData, customer: { id: item.id } });
                          setShowMenuCustomers(false);
                        }}
                      >
                        {item.fantasyName || item.name}
                      </li>
                    ))}
                  </ul>
                )}
              </Form.Group>

              <Form.Group as={Col} md="3" className="mb-3">
                <Form.Label>Contato</Form.Label>
                <Form.Select
                  name="contact"
                  value={formData.contact?.id}
                  onChange={e => handleChange(e, true)}>
                  <option>Selecione...</option>
                  {contacts.map((contact) => (
                    <option key={contact.id} value={contact.id}>
                      {contact.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} md="1" className="mb-3">
                <Form.Label>Ativo</Form.Label>
                <Form.Check
                  type="switch"
                  name="inactive"
                  checked={!formData.inactive}
                  onChange={() => setFormData({ ...formData, inactive: !formData.inactive })} />
              </Form.Group>

              <Form.Group as={Col} md="12" className="mb-3">
                <Form.Label>Descrição</Form.Label>
                <Editor
                  data={formData.description}
                  value={value => setFormData({ ...formData, description: value })} />
              </Form.Group>
            </Row>

            <Row>
              <Flex
                role="button"
                className="bg-100 py-2 mb-2"
                alignItems={"center"}
                onClick={() => setShowCustomFields(!showCustomFields)}>
                <FontAwesomeIcon
                  icon={showCustomFields ? faChevronUp : faChevronDown}
                  size="sm"
                  className="me-2" />
                <h5>Campos Personalizados</h5>
              </Flex>

              {showCustomFields &&
                asset.assetProperties?.map((prop, i) => (
                  <Form.Group className="mb-3" as={Col} md={6} key={prop.id}>
                    <Form.Label>{prop.name} {prop.required && <span className="text-danger"> *</span>}</Form.Label>
                    {renderPropertyInput(prop, formData.properties[i]?.value, handleChangeCustomFields)}
                  </Form.Group>
                ))
              }
            </Row>

            <Flex className="w-100" justifyContent="end">
              <Button
                as={Link}
                to={`/assets/${id}`}
                variant="falcon-default"
                className="me-2">Cancelar</Button>
              <Button type="submit">Salvar</Button>
            </Flex>
          </Form>
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>
  );
};

const renderPropertyInput = (property, value, handleChange) => {
  const { dataType, id, dropdownOptions, required } = property;

  switch (dataType.dataType) {
    case "TEXT":
      return <Form.Control
        type="text"
        value={value}
        required={required}
        onChange={(e) => handleChange(e, id, dataType)} />;
    case "INTEGER":
      return <Form.Control
        type="number"
        value={value}
        required={required}
        onChange={(e) => handleChange(e, id, dataType)} />;
    case "DECIMAL":
      return <Form.Control
        type="number"
        step="0.01"
        value={value}
        required={required}
        onChange={(e) => handleChange(e, id, dataType)} />;
    case "TEXT_AREA":
      return <Form.Control
        as="textarea"
        rows={2}
        value={value}
        required={required}
        onChange={(e) => handleChange(e, id, dataType)} />;
    case "CHECKBOX":
      return <Form.Check
        type="checkbox"
        checked={!!value}
        required={required}
        onChange={(e) => handleChange(e, id, dataType)} />;
    case "DROPDOWN":
      return (
        <Form.Select
          value={value}
          required={required}
          onChange={(e) => handleChange(e, id, dataType)}>
          <option>Selecione...</option>
          {dropdownOptions.map((option) => (
            <option key={option.id} value={option.value}>
              {option.label}
            </option>
          ))}
        </Form.Select>
      );
    case "DATETIME":
      return <Form.Control
        type="datetime-local"
        value={value}
        required={required}
        onChange={(e) => handleChange(e, id, dataType)} />;
    default:
      return <p>Tipo não suportado</p>;
  }
};

export default AssetEdit;
