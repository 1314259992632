import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import DOMPurify from "dompurify";
import React, { useRef, useState } from 'react';
import { Card, } from 'react-bootstrap';

const AssetDescription = ({ data }) => {
  const divDescription = useRef(null);
  const [showBody, setShowBody] = useState(true);
  const sanitizedHtml = DOMPurify.sanitize(data.description);

  const isEmptyDescription = !data.description || data.description.trim().length < 1;

  return (
    <Card className="w-100 mb-3">
      <Flex
        className="mw-100 p-3 bg-100 rounded-top-4"
        onClick={() => setShowBody(!showBody)}
        role="button"
        justifyContent="between"
      >
        <h6 className="m-0">DESCRIÇÃO</h6>
        <FontAwesomeIcon icon={showBody ? faChevronUp : faChevronDown} size="sm" />
      </Flex>
      {showBody && (
        <Card.Body className="w-100 p-1">
          {isEmptyDescription ? (
            <p className="ms-2" style={{ fontStyle: 'italic', opacity: 0.7 }}>
              Nenhuma descrição definida
            </p>
          ) : (
            <div
              ref={divDescription}
              style={{ textAlign: 'justify' }}
              className="ps-2 pe-3 w-100 overflow-x-hidden rich-text-content"
              dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
          )}
        </Card.Body>
      )}
    </Card>
  );
};

export default AssetDescription;