import { faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import CustomerRegistrationOffcanvas from 'components/offcanvas/CustomerRegistrationOffcanvas';
import Pagination from 'components/pagination';
import useApi from 'helpers/api';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row
} from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';

const CustomerRegistration = () => {
  const api = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [customers, setCustomers] = useState({
    content: [
      {
        type: '',
        corporateName: '',
        fantasyName: '',
        cnpj: '',
        stateRegistration: '',
        name: '',
        cpf: '',
        inactive: false
      }
    ]
  });
  const [filters, setFilters] = useState({
    page: 0,
    size: 10,
    includeInactives: false,
    name: ''
  });
  const [filterNameCustomer, setFilterNameCustomer] = useState('');

  const getData = async () => {
    setIsLoading(true);
    const queryParams = new URLSearchParams(filters).toString();
    const response = await api.customers.list(queryParams);
    if (response.content) setCustomers(response);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, [filters]);

  const columns = [
    {
      accessorKey: 'name',
      header: 'Nome',
      meta: {
        headerProps: { className: 'text-900' }
      },
      cell: rowData => {
        const { id, type, fantasyName, name } = rowData.row.original;
        return (
          <Link to={`/registration/customers/${id}`}>
            {type === 'JURIDICAL_PERSON' ? fantasyName : name}
          </Link>
        );
      }
    },
    {
      accessorKey: 'status',
      header: 'Status',
      meta: {
        headerProps: { className: 'text-900' }
      },
      cell: rowData => {
        const { inactive } = rowData.row.original;
        return inactive ? (
          <span className="text-danger">Inativo</span>
        ) : (
          <span className="text-success">Ativo</span>
        );
      }
    },
    {
      accessorKey: 'actions',
      header: 'Ações',
      meta: {
        headerProps: { className: 'text-900' }
      },
      cell: rowData => {
        const item = rowData.row.original;
        return (
          <Link
            className="btn btn-link btn-sm"
            to={`/registration/customers/${item.id}`}
          >
            <FontAwesomeIcon icon={faPen} size="sm" />
          </Link>
        );
      }
    }
  ];

  const table = useAdvanceTable({
    data: customers.content,
    columns
  });

  const handleCloseModalRegister = () => {
    setShowModal(false);
  };

  return (
    <FalconComponentCard>
      <FalconComponentCard.Header title="Clientes">
        <Button
          type="button"
          variant="primary"
          size="sm"
          onClick={() => setShowModal(true)}
        >
          <FontAwesomeIcon icon={faPlus} size="sm" className="me-2" />
          Novo
        </Button>
      </FalconComponentCard.Header>
      <FalconComponentCard.Body className="bg-white">
        <CustomerRegistrationOffcanvas
          createdCustomer={item => { }}
          showModal={showModal}
          isSuccess={response => {
            if (response) getData();
          }}
          handleClose={() => handleCloseModalRegister()}
        />
        <Row className="justify-content-between g-0">
          <Col className="d-flex align-items-center col-auto pe-3 mb-3">
            <InputGroup className="position-relative input-search-width">
              <FormControl
                size="sm"
                id="search"
                type="search"
                className="shadow-none"
                placeholder="Buscar por nome"
                value={filterNameCustomer}
                onChange={e => setFilterNameCustomer(e.target.value)}
                style={{ borderRadius: '1px 0px 0px 1px' }}
              />
              <Button
                size="sm"
                variant="outline-secondary"
                className="border-300 hover-border-secondary"
                onClick={() =>
                  setFilters(prevFilters => ({
                    ...prevFilters,
                    page: 0,
                    name: filterNameCustomer
                  }))
                }
              >
                <FontAwesomeIcon icon="search" className="fs-10" />
              </Button>
            </InputGroup>
          </Col>

          <Col className="col-auto px-3">
            <Form.Select
              size="sm"
              className="mb-3 rounded-pill"
              onChange={e =>
                setFilters(prevFilters => ({
                  ...prevFilters,
                  includeInactives: e.target.value
                }))
              }
            >
              <option value="true">Filtro por status</option>
              <option value="true">Todos</option>
              <option value="false">Somente ativos</option>
            </Form.Select>
          </Col>
        </Row>
        {isLoading ? (
          <Skeleton count={10} height={44} baseColor="#5E6E82" />
        ) : (
          <>
            <AdvanceTableProvider {...table}>
              <AdvanceTable
                table
                headerClassName="bg-100 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  bordered: true,
                  striped: true,
                  className: 'fs-10 mb-0 overflow-hidden'
                }}
              />
            </AdvanceTableProvider>
            <Pagination
              data={customers}
              filters={filters}
              setFilters={setFilters}
            />
          </>
        )}
      </FalconComponentCard.Body>
    </FalconComponentCard>
  );
};

export default CustomerRegistration;
