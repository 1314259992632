import useApi from 'helpers/api';
import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Skeleton from 'react-loading-skeleton';

const ProfileDetails = () => {
  const api = useApi();

  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState('');
  const [myProfile, setMyProfile] = useState({});

  useEffect(() => {
    setIsLoading(true);
    getUserIdAuthenticated();
  }, []);

  useEffect(() => {
    if (userId)
      getData();
  }, [userId]);

  const getData = async () => {
    let response = await api.agents.myProfile(userId);
    setMyProfile(response);
    setIsLoading(false);
  };

  const getUserIdAuthenticated = async () => {
    let response = await api.agents.getUserIdAuthenticated();
    if (response)
      setUserId(response);
  };

  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title>Detalhes do perfil</Card.Title>
        </Card.Header>
        {isLoading ? (
          <div className='m-2'>
            <Skeleton count={4} height={44} baseColor="#5E6E82" />
          </div>
        ) : (
          <Card.Body>
            <Row className="g-3">
              <Col md={12}>
                <h6>Nome completo</h6>
                <p className="fs-10 mb-0 text-600">{myProfile.firstName} {myProfile.lastName}</p>
              </Col>
              <Col md={12}>
                <h6>E-mail</h6>
                <p className="fs-10 mb-0 text-600">{myProfile.email}</p>
              </Col>
              <Col md={12}>
                <h6>Telefone</h6>
                <p className="fs-10 mb-0 text-600">{myProfile.phone}</p>
              </Col>
            </Row>
          </Card.Body>
        )}
      </Card>
    </>
  );
};

export default ProfileDetails;