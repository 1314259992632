import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const AssetHeader = ({ assetId }) => {
  return (
    <Card>
      <Card.Header className="d-flex flex-between-center w-100">
        <IconButton
          variant="falcon-default"
          size="sm"
          icon="arrow-left"
          as={Link}
          to={'/assets'}
        />

        <Flex>
          <IconButton
            variant="falcon-default"
            size="sm"
            icon="edit"
            transform="shrink-2"
            iconAlign="middle"
            as={Link}
            to={`/assets/${assetId}/edit`}
          >
            <span className="d-none d-md-inline-block w-90">Editar</span>
          </IconButton>
        </Flex>
      </Card.Header>
    </Card>
  );
};

export default AssetHeader;