import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconComponentCard from 'components/common/FalconComponentCard';
import Flex from 'components/common/Flex';
import { formatDateTime } from 'helpers/utils';
import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import { tdStyling } from './AssetContact';

const AssetPrimaryInfo = ({ data }) => {
  const [showBody, setShowBody] = useState(true);
  return (
    <FalconComponentCard>
      <Flex
        className="p-3 bg-100 w-100 rounded-top-4"
        onClick={() => setShowBody(!showBody)}
        role="button" justifyContent="between">
        <h6 className="m-0">INFORMAÇÕES PRIMÁRIAS</h6>
        <FontAwesomeIcon icon={showBody ? faChevronUp : faChevronDown} size="sm" />
      </Flex>
      {showBody &&
        <Table>
          <tbody>
            <tr style={{ borderColor: 'transparent' }}>
              <td className={`fw-semibold w-50 mt-0 ${tdStyling}`}>Nome</td>
              <td className={`pb-0`}>{data.name || '---'}</td>
            </tr>
            <tr style={{ borderColor: 'transparent' }}>
              <td className={`fw-semibold w-50 pb-0`}>Tipo</td>
              <td className={`pb-0`}>{data.assetType?.name || '---'}</td>
            </tr>
            <tr style={{ borderColor: 'transparent' }}>
              <td className="fw-semibold w-50 pb-0">Número Serial</td>
              <td className="pb-0">{data.serialNumber || '---'}</td>
            </tr>
            <tr style={{ borderColor: 'transparent' }}>
              <td className="fw-semibold w-25 pb-0">Criado em</td>
              <td className="pb-0">
                {
                  data.creationDate ? formatDateTime(data.creationDate) : '---'
                }
              </td>
            </tr>
            <tr style={{ borderColor: 'transparent' }}>
              <td className="fw-semibold w-50 pb-0">Atualizado em</td>
              <td className="pb-0">
                {
                  data.lastUpdateDate ? formatDateTime(data.lastUpdateDate) : '---'
                }
              </td>
            </tr>
          </tbody>
        </Table>
      }
    </FalconComponentCard>
  );
};

export default AssetPrimaryInfo;