import useApi from 'helpers/api';
import { useEffect, useState } from "react";
import { Col, Row, } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import AssetContact from './AssetContact';
import AssetCustomFields from './AssetCustomFields';
import AssetDescription from './AssetDescription';
import AssetDetailsFromRMM from './AssetDetailsFromRMM';
import AssetHeader from './AssetHeader';
import AssetMainCard from './AssetMainCard';
import AssetPrimaryInfo from './AssetPrimaryInfo';
import AssetSiteInfo from './AssetSiteInfo';
import DattoDevice from './DattoDevice';

const AssetDetails = () => {
  const { id } = useParams();
  const api = useApi();
  const [asset, setAsset] = useState({});

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const response = await api.assets.find(id);
    response.id && setAsset(response);
  };
  return (
    <>
      <AssetHeader assetId={id} />
      <Row className='g-2'>
        <Col xl={9} className="d-flex flex-column justify-content-start align-items-center mt-3">
          <AssetMainCard />
          <AssetDescription data={asset} />
          {asset.dattoDevice && <AssetDetailsFromRMM data={asset.dattoDevice} />}
          <AssetCustomFields data={asset} />
        </Col>
        <Col xl={3} className='mt-3'>
          {asset.dattoDevice && <DattoDevice data={asset.dattoDevice} />}
          {asset.dattoDevice && <AssetSiteInfo data={asset.dattoDevice} />}
          <AssetContact data={asset} />
          <AssetPrimaryInfo data={asset} />
        </Col>
      </Row>
    </>
  );
};

export default AssetDetails;
