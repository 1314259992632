import React, { useState } from 'react';
import { Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faCopy, faCheck } from '@fortawesome/free-solid-svg-icons';
import FalconComponentCard from 'components/common/FalconComponentCard';
import Flex from 'components/common/Flex';

const AssetSiteInfo = ({ data }) => {
  const [showBody, setShowBody] = useState(true);
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    if (data.siteUid) {
      try {
        await navigator.clipboard.writeText(data.siteUid);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      } catch (err) {
        console.error('Falha ao copiar o texto: ', err);
      }
    }
  };

  return (
    <FalconComponentCard>
      <Flex
        className="p-3 bg-100 w-100 rounded-top-4"
        onClick={() => setShowBody(!showBody)}
        role="button"
        justifyContent="between"
      >
        <h6 className="m-0">INFORMAÇÕES DO SITE</h6>
        <FontAwesomeIcon icon={showBody ? faChevronUp : faChevronDown} size="sm" />
      </Flex>
      {showBody && (
        <Table>
          <tbody>
            <tr style={{ borderColor: 'transparent' }}>
              <td className="fw-semibold w-50">Nome do Site</td>
              <td >{data.siteName}</td>
            </tr>
            <tr style={{ borderColor: 'transparent' }}>
              <td className="fw-semibold w-50">UID do Site</td>
              <td className='p-1'>
                {data.siteUid ? (
                  <OverlayTrigger
                    placement="left"
                    overlay={
                      <Tooltip>
                        {copied ? 'Copiado' : 'Copiar'}
                      </Tooltip>
                    }
                  >
                    <span
                      role="button"
                      onClick={handleCopy}
                      style={{ cursor: 'pointer' }}
                    >
                      {data.siteUid}{''}
                      <FontAwesomeIcon
                        icon={copied ? faCheck : faCopy}
                        size="sm"
                        className="ms-1"
                      />
                    </span>
                  </OverlayTrigger>
                ) : (
                  '---'
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      )}
    </FalconComponentCard>
  );
};

export default AssetSiteInfo;