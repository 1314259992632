import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import FailedRequest from "components/requests-response/FailedRequest";
import SuccessRequest from "components/requests-response/SuccessRequest";
import useApi from 'helpers/api';
import { useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

const CustomPropertyRegistrationModal = ({
  assetTypeId,
  show,
  close,
  refresh,
  data,
  setData,
  isCreate }) => {
  const api = useApi();
  const [body, setBody] = useState({
    name: '',
    dataType: '',
    required: false,
    dropdownOptions: []
  });
  const [dataTypes, setDataTypes] = useState([]);
  const [labelDropdown, setLabelDropdown] = useState('');
  const [valueDropdown, setValueDropdown] = useState('');

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState({});
  const [success, setSuccess] = useState('');
  const [validated, setValidated] = useState(false);
  const form = useRef(null);

  useEffect(() => {
    if (show) {
      getDataTypes();
    }
  }, [show]);

  useEffect(() => {
    if (isCreate) {
      setBody({
        name: '',
        dataType: '',
        required: false,
        dropdownOptions: []
      });
    }
  }, [isCreate]);

  useEffect(() => {
    if (data) {
      setBody({
        name: data.name,
        dataType: data.dataType && data.dataType.dataType,
        required: data.required,
        dropdownOptions: data.dropdownOptions
      });
    }
  }, [data]);

  const onSubmit = async e => {
    if (form.current.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      setValidated(false);

      let response;
      if (data) {
        response = await api.assetTypes.properties.edit(assetTypeId, data.id, body);
        setSuccess("Campo editado com sucesso");

      } else {
        response = await api.assetTypes.properties.create(assetTypeId, body);
        setSuccess("Campo adicionado com sucesso");

        setBody({ name: '', dataType: '', required: false, dropdownOptions: [] });
        setValidated(false);
      }

      if (response.id) {
        setShowSuccess(true);
        close();
        refresh();
      } else {
        setError(response);
        setShowError(true);
      }
      setData(null);
    }
  };

  const getDataTypes = async () => {
    const response = await api.assetTypes.dataTypes();

    if (Array.isArray(response))
      setDataTypes(response);
  };

  const addDropdownOption = () => {
    if (labelDropdown && valueDropdown) {
      setBody({
        ...body,
        dropdownOptions: [...body.dropdownOptions, { label: labelDropdown, value: valueDropdown }]
      });
      setLabelDropdown('');
      setValueDropdown('');
    }
  };

  const deleteDropdownOption = async (id, value) => {
    if (id) {
      const response = await api.assetTypes.properties.deleteDropdownOption(assetTypeId, data.id, id);

      if (response.ok) {
        setBody({
          ...body,
          dropdownOptions: body.dropdownOptions.filter(option => option.id !== id)
        });
      } else {
        setError(response);
        setShowError(true);
      }
    } else {
      setBody({
        ...body,
        dropdownOptions: body.dropdownOptions.filter(option => option.value !== value)
      });
    }
  };

  return (
    <>
      <SuccessRequest
        showAlert={showSuccess}
        setShowAlert={() => setShowSuccess(false)}
        message={success} />
      <FailedRequest
        showAlert={showError}
        setShowAlert={() => setShowError(false)}
        message={error.userMessage} />
      <Modal show={show} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>{isCreate ? "Nova " : "Editar "} propriedade</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form validated={validated} ref={form}>
            <Form.Group className="mb-3">
              <Form.Label>Nome <span className="text-danger">*</span></Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Nome"
                autoFocus
                value={body.name}
                onChange={e => setBody({ ...body, name: e.target.value })}
              />
              <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Insira um nome.</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Tipo de dado <span className="text-danger">*</span></Form.Label>
              <Form.Select
                value={body.dataType}
                required
                onChange={e => setBody({ ...body, dataType: e.target.value })}
              >
                <option value="" key="">Selecione...</option>
                {dataTypes.map(({ dataType, value }) => (
                  <option value={dataType} key={dataType}>{value}</option>
                ))}
              </Form.Select>
              <Form.Control.Feedback>Muito bom!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">Insira um tipo de dado.</Form.Control.Feedback>
            </Form.Group>

            {body.dataType === 'DROPDOWN' && (
              <>
                <Form.Group className="mb-3">
                  <Form.Label>Adicione as opções possíveis</Form.Label>

                  <Flex>
                    <Form.Control
                      type="text"
                      placeholder="Identificador"
                      value={labelDropdown}
                      onChange={e => {
                        const label = e.target.value;
                        setLabelDropdown(label);
                        const formattedValue = label.toLowerCase().replace(/ /g, "_");
                        setValueDropdown(formattedValue);
                      }}
                      className="me-2"
                    />

                    <Form.Control
                      type="text"
                      placeholder="Valor"
                      value={valueDropdown}
                      onChange={e => setValueDropdown(e.target.value)}
                    />

                    <Button
                      variant="falcon-default"
                      className="ms-2"
                      onClick={() => addDropdownOption()}>Adicionar</Button>
                  </Flex>
                </Form.Group>

                <div className="table-responsive scrollbar">
                  <table className="table fs-10 mb-3">
                    <thead className="bg-200 text-900">
                      <tr>
                        <th>Opções</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {body.dropdownOptions.map((dropdownOption, i) => (
                        <tr key={i}>
                          <td data-value={dropdownOption.value}>{dropdownOption.label}</td>
                          <td>
                            <Button
                              variant="link"
                              size="sm"
                              className="text-danger"
                              onClick={() => deleteDropdownOption(dropdownOption.id, dropdownOption.value)}>
                              x
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}

            <Form.Group className="mb-3">
              <Form.Label>Obrigatório <span className="text-danger">*</span></Form.Label>
              <Form.Check
                type="switch"
                value={body.required}
                checked={body.required}
                onChange={() => setBody({ ...body, required: !body.required })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="falcon-default" onClick={close}>
            Fechar
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            <FontAwesomeIcon icon={faSave} className="me-2" />
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CustomPropertyRegistrationModal;