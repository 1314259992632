import FailedRequest from "components/requests-response/FailedRequest";
import SuccessRequest from "components/requests-response/SuccessRequest";
import useApi from "helpers/api";
import { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";

const TicketContactEditModal = ({ show, close, ticket, setContact }) => {
  const api = useApi();
  const [contacts, setContacts] = useState([]);
  const [body, setBody] = useState({
    contactCustomer: { id: 0 }
  });

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");

  const customerId = ticket.customer?.id;
  const isDisabledForm = ticket.status?.status == "COMPLETED" || ticket.status?.status == "CLOSED";

  useEffect(() => {
    getContacts();
  }, [customerId]);

  useEffect(() => {
    setBody({ contactCustomer: { id: parseInt(ticket.contactCustomer?.id) } });
  }, [ticket]);

  const getContacts = async () => {
    const filter = {
      page: 0,
      size: 100
    };
    const queryParams = new URLSearchParams(filter).toString();
    if (customerId) {
      let response = await api.customersContacts.list(
        customerId,
        queryParams
      );
      setContacts(response.content);
    }
  };

  const editContact = async e => {
    setBody({ contactCustomer: { id: parseInt(e.target.value) } });
    const response = await api.tickets.edit(ticket?.id, { contactCustomer: { id: parseInt(e.target.value) } });

    if (response.id) {
      setBody({ contactCustomer: { id: parseInt(response.contactCustomer?.id) } });
      setContact(response?.contactCustomer);
      setShowSuccess(true);
      close();
    } else {
      setError(response.userMessage);
      setShowError(true);
    }
  };

  return (
    <>
      <SuccessRequest
        showAlert={showSuccess}
        setShowAlert={() => setShowSuccess(false)}
        message="Contato alterado com sucesso" />
      <FailedRequest
        showAlert={showError}
        setShowAlert={() => setShowError(false)}
        message={error} />
      <Modal show={show} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Alterar contato</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="editForm.Name">
              <Form.Label>Contato <span className="text-danger"> *</span></Form.Label>
              <Form.Select
                value={body.contactCustomer?.id}
                onChange={editContact}
                size='sm'
                disabled={isDisabledForm}>
                {contacts.map(item => (
                  <option value={item.id} key={item.name}>{item.name}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TicketContactEditModal;