import { Modal } from "react-bootstrap";

const NewReleaseModal = ({ show, close }) => {
  return (
    <Modal show={show} onHide={close} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>🎉 Novidades da Nova Release! 🎉</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>
          Estamos muito felizes em trazer as mais recentes atualizações e melhorias para você!
          Confira abaixo o que há de novo nesta versão:
        </p>

        <h5>🚀 Novidades Exclusivas desta Versão:</h5>

        <ul>
          <li>
            <strong>Cores Ajustadas nos Gráficos da Dashboard</strong>
            <p>
              Agora os gráficos da dashboard mudam de cor automaticamente quando você altera o tema, deixando tudo mais consistente e visualmente agradável.
            </p>
          </li>
          <li>
            <strong>Expansão dos Detalhes do Ticket</strong>
            <p>
              Agora você pode expandir a área de detalhes do ticket para ver mais informações de forma clara e organizada.
            </p>
          </li>
          <li>
            <strong>Chat do MSP Talks em Nova Janela</strong>
            <p>
              O chat do MSP Talks agora abre em uma nova janela, facilitando a navegação e permitindo que você continue usando a plataforma enquanto conversa.
            </p>
          </li>
          <li>
            <strong>Edição de Ativos</strong>
            <p>
              Adicionamos a funcionalidade de edição de ativos, permitindo que você faça ajustes diretamente na plataforma de forma rápida e prática.
            </p>
          </li>
          <li>
            <strong>Melhoria no Carregamento das Telas</strong>
            <p>
              Agora, enquanto as telas principais carregam, você verá uma animação (skeleton) que melhora a experiência, deixando o processo mais suave.
            </p>
          </li>
          <li>
            <strong>Visualização Aprimorada dos Ativos</strong>
            <p>
              A tela de ativos foi redesenhada para ser mais intuitiva e fácil de usar, ajudando você a encontrar o que precisa com mais rapidez.
            </p>
          </li>
          <li>
            <strong>Listas Suspensas Personalizadas</strong>
            <p>
              Agora você pode criar listas suspensas personalizadas nos campos de ativos, permitindo que organize as informações da maneira que preferir.
            </p>
          </li>
          <li>
            <strong>Filtros na Tela de Ativos</strong>
            <p>
              Adicionamos filtros por cliente e tipo na tela de ativos, facilitando a busca e organização dos seus recursos.
            </p>
          </li>
          <li>
            <strong>Filtros na Tela de Alertas</strong>
            <p>
              Agora você pode filtrar os alertas por cliente e dispositivo, tornando mais fácil identificar e resolver problemas específicos.
            </p>
          </li>
          <li>
            <strong>Bloqueio de E-mails Duplicados</strong>
            <p>
              O sistema agora impede que e-mails já cadastrados na lista negra sejam adicionados novamente, evitando duplicidades e simplificando a gestão.
            </p>
          </li>
        </ul>

        <p>
          Aproveite essas novidades e fique de olho, pois temos mais melhorias incríveis a caminho! 🚀
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default NewReleaseModal;